import React from "react"
import Nav from "../../components/nav.js"
import Lifestyle from "../ingredients/@lifestyle.js"

export default function Lifestyle_Nav() {
  return (
    <Nav>
      <Lifestyle />
    </Nav>
  )
}